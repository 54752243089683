import axios from "axios";
import { message as messageAlert} from "antd";

import { Api, apiErrorRes, headers, jwtExpired, setToken, thumbUrl } from "./constant";

const url = `${Api}/employment-contracts`;

export const getList = (id) => {
    return axios
        .get(url+ `?employeeId=${id}`, {headers:headers()})
        .then((res) => {
            const { success, data, message } = res.data;
            jwtExpired(message)
            if (success)  setToken(res?.headers?.authorization)
            return { success, data };
        })
        .catch((err) => {
            return apiErrorRes(err, 1, 5);
        });
};

export const getRecord = (id) => {
    return axios
        .get(url + `/${id}`, {headers:headers()})
        .then((res) => {
            const { success, data, message } = res.data;
            jwtExpired(message)
            if (success)  {
                setToken(res?.headers?.authorization)
                data.leaveRequestPolicyId = data.leaveRequestPolicyId?? 0
                data.file= data.fileId ? [{
                    id: data.file.id,
                    createdAt: data.file.createdAt,
                    fileId: data.file.id,
                    uid: data.file.uniqueName,
                    name: data.file.originalName,
                    type: data.file.type,
                    url: `${Api}/files/${data.file.uniqueName}`,
                    thumbUrl: thumbUrl(data.file.type)
                }] : []
            }
            return { success, data };
        })
        .catch((err) => {
            return apiErrorRes(err, 1, 5);
        });
};

export const addList = (data) => {
    messageAlert.loading({ content: 'Loading...', key: 1 })
    return axios
        .post(url, data, {headers:headers()})
        .then((res) => {
            const { success, message } = res.data;
            jwtExpired(message)
            messageAlert.success({ content: message, key: 1})
            if (success) setToken(res?.headers?.authorization)
            return {success};
        })
        .catch((err) => {
            return apiErrorRes(err, 1, 5);
        });
};

export const delList = (id) => {
    return axios
        .delete(url + `/${id}`, {headers:headers()})
        .then((res) => {
            const { success, message } = res.data;
            jwtExpired(message)
            if (success) {
                setToken(res?.headers?.authorization)
            }
            return {success};
        })
        .catch((err) => {
            return apiErrorRes(err, 1, 5);
        });
};

export const editList = (id, data) => {
    messageAlert.loading({ content: 'Loading...', key: id })
    return axios
        .put(url + `/${id}`, data, {headers:headers()})
        .then((res) => {
            const { success, message } = res.data;
            jwtExpired(message)
            messageAlert.success({ content: message, key: id})
            if (success) {
                setToken(res?.headers?.authorization)
            }
            return {success};
        })
        .catch((err) => {
            return apiErrorRes(err, 1, 5);
        });
};
