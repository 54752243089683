import { Button, Col, DatePicker, Dropdown, Menu, Popconfirm, Row, Select, Table, Tag, Typography,Modal, Form, Input, Tooltip, Checkbox} from 'antd';
import { SettingOutlined, CheckCircleOutlined, AuditOutlined, CheckOutlined} from '@ant-design/icons'; //Icons
import React, { useEffect, useState } from 'react'
import { entityProjects, getManageProjects, getUserProjects } from '../../service/constant-Apis';
import { expenseSheetActions, getApprovalExpenseSheets, getExpenseSheet } from '../../service/expenseSheet-Apis';
import { dateClosed, formatCurrency, formatDate, getParams, localStore, R_STATUS, STATUS_COLOR } from '../../service/constant';
// import { expensesData as dummyExpensesData } from '../DummyData';
import moment from 'moment'
import ExpenseSheetModal from './Modals/ExpenseSheetModal';
import { tableSorter } from '../../components/Core/Table/TableFilter';
import {Tag_s} from '../../components/Core/Custom/Index';

const { Title } = Typography
const { RangePicker } = DatePicker
let modal = ""

const ExpenseApproval = () => {
	let {startDate, endDate, projectId, sheetId} = getParams(window.location.search)

	const [projects, setProjects] = useState([]);
	const [selectedRows, setSelectedRows] = useState({keys: [], data: []});
	const [expenseData, setExpenseData] = useState();
	const [openModal, setOpenModal] = useState(false);
	const [permission, setPermission] = useState({});
	// const [adminView, setAdminView] = useState(false);
	// const [disableSubmit, setDisableSubmit] = useState(true);
	const [queryRequest, setQueryRequest] = useState({
		filterSatrtDate: startDate? moment(startDate, 'DD-MM-YYYY')  :moment().startOf('month'),
		filterEndDate: endDate? moment(endDate, 'DD-MM-YYYY'): moment().endOf('month'),
		filterProject: projectId? parseInt(projectId): null,
	});  
	const { filterSatrtDate, filterEndDate, filterProject } = queryRequest

	const columns = [
    {
      title: 'Code',
      dataIndex: 'id',
      render: (text) => `00${text}`,
      ...tableSorter('id', 'number'),
    },
    {
      title: 'Title',
      dataIndex: 'label',
      ...tableSorter('label', 'string'),
    },
    {
      title: 'Project',
      dataIndex: 'projectName',
      ...tableSorter('projectName', 'string'),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      align: 'center',
	  render: (text) => formatCurrency(text),
      ...tableSorter('amount', 'number'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      render: (text, record) => 
	  <span>
          <Tag_s text={text} />{' '}
          {record.notes && (
            <Tooltip title={record.notes} placement="top" destroyTooltipOnHide>
              <AuditOutlined />
            </Tooltip>
          )}
        </span>,
      ...tableSorter('status', 'string', true, 'SB')
	},
    {
      title: 'Submitted At',
      dataIndex: 'submittedAt',
      align: 'center',
      render: (text) => formatDate(text, true, true),
      ...tableSorter('submittedAt', 'date'),
    },
    {
      title: 'Submitted By',
      dataIndex: 'submittedBy',
      align: 'center',
      ...tableSorter('submittedBy', 'string'),
    },
	{
		title: 'Billable',
		dataIndex: 'isBillable',
		align: 'center',
		render: (value) => (
			value && <CheckOutlined />
			// <Checkbox defaultChecked={false} checked={value} />
		)
	},
	{
      title: '...',
      key: 'action',
      align: 'center',
      width: '1%',
      // width: '155',
      render: (value, record, index) => (
        
		<Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="edit"
                onClick={() =>
					onOpenModal(record.id, index)
				// setOpenModal({ ...record.id, index, adminView: true })
                //   setOpenModal({ ...record, index, adminView: true })
                }
              >
                View
              </Menu.Item>
            </Menu>
          }
        >
          <Button size="small">
            <SettingOutlined />
          </Button>
        </Dropdown>
      ),
    },
  	];

	useEffect(() => {
		gettingProject();
	}, []);

	useEffect(() => {
		gettingExpenseSheets(queryRequest);
	}, [queryRequest]);

	// my work 
	
	const onSelectChange = (newSelectedRowKeys, selectedRow) => {
		let cantApprove = false, cantUnapprove = false, cantReject = false
        selectedRow.forEach(el =>{
            if(el.status === 'SB'){
				cantUnapprove = true;
			}
            if(el.status === 'AP'){
				cantReject = true;
				cantApprove = true;
			}
			if (el.status === 'RJ' || el.status === 'SV') {
				cantUnapprove = true;
				cantReject = true;
				cantApprove = true;
			}
			
        })
		setSelectedRows({keys:newSelectedRowKeys, data: selectedRow, cantReject, cantApprove, cantUnapprove});
	};
	
	const rowSelection = {
		selectedRowKeys: selectedRows.keys,
		onChange: onSelectChange,
		// preserveSelectedRowKeys: false,
   		getCheckboxProps: (record)=> ({disabled: ['RJ', 'SV'].includes(record.status) || dateClosed(record.submittedAt) })
	};

	// for get all project 
	const gettingProject = () => {
		const { id, permissions} = localStore();
		const { EXPENSES = {}} = JSON.parse(permissions)
		setPermission(EXPENSES);	
		getManageProjects({resource: 'LEAVE_REQUESTS', phase:true}).then((res) => {
			if (res.success) {
				res.data.unshift({value:0, label: 'No Project'})
				setProjects(res.data); 
			}
				
		})
	}

	const gettingExpenseSheets = (filters) => {
		getApprovalExpenseSheets(filters).then((res) => {
			if (res.success) {
				setExpenseData(res.data); 
				let length = res.data.length
				if (sheetId){ //selecting timesheet from queryparams
					for(let i = 0; i<length; i++){
						let data = res?.data?.[i] ?? {}
						if (data.id == sheetId){
							onSelectChange([data.id], [data])
							break; //break if timesheet found
						}
					}
				}
			}
		})
	}

	const callBack = (data, index) => {
		let fIndex = expenseData.findIndex(el=> el.id === data.id)
		let exp = expenseData;
   		 if (index >= 0) {
			exp[fIndex] = data; 
		}
		setExpenseData([...exp]);  
		setOpenModal(false);
  }

	// modals 
	const closeModal = () => {
		setOpenModal(false);
	}

	// for getting expense approval
	const onOpenModal = (id, index) =>{
		//send true if you need to call expenses for expense sheet
		
		getExpenseSheet(id).then(res=>{
		  if (res.success){
			setOpenModal({ ...res.data, index, adminView: true })
		  }
		})
	  } 
	

	const multiAction = (stage)=> {
		const {data =[] } = selectedRows
		let length = data.length
		let content = <div>{ 
		  data.map(({label, projectName, projectType}, index) =>(
				<div key={index}>
					{label}{length -1 > index && ','  }  
				</div> 
			)) 
		}
		<div style={{margin: 10}}>
			<Form  id={'my-form' } onFinish={(values)=> onActionFinished(values, stage)} >
				<Form.Item noStyle name={'notes'} >
					<Input.TextArea
						placeholder="Enter Your Notes...."
						autoSize={{ minRows: 3, maxRows: 10 }}
						allowClear
					/>
					</Form.Item>
			</Form>
		</div>
	</div>

		modal = Modal.confirm({
		  title: `Do you wish to ${stage} listed sheet${length >1 ? 's': ''}`,
		  icon: <CheckCircleOutlined />,
		  content: content,
		  // okButtonProps: {danger: stage === 'unapprove'??true},
		  okText: 'Yes',
		  cancelText: 'No',
		  okButtonProps: {danger: stage === 'reject'??true, htmlType: 'submit', form: 'my-form'  },
		  // onOk:()=>{
		  //   //   this.actionTimeSheet(stage) 
		  //     OutcomeAction()
		  //     modal.destroy();
		  // }
		});
	}

	const onActionFinished = (formValues, stage) =>{
	const {notes} = formValues
	OutcomeAction(stage, notes) 
	modal.destroy();
	}

	const OutcomeAction = (stage, actionNotes) =>{
	const {keys =[] } = selectedRows
	let obj={sheets: keys, notes: actionNotes }
	expenseSheetActions(`/${stage}Many`, obj).then(res=>{
		if (res.success){
			// data[index]['isApproved'] = true
			setSelectedRows({keys: [], data: []})
			gettingExpenseSheets()
		}
	})
	}

	const onCheckChanged = async({target}) =>{
		let value = target.checked
		let query = {resource: 'LEAVE_REQUESTS'} ;
		if (!value){
		  query.phase =true 
		}
		let res = getManageProjects(query)
		this.setState({milestones: res.success? res.data : [],})
	  }
	
	return (<>
		<Row justify='space-between'>
			<Col>
				<Title level={4}>Expense Sheet Approval</Title>
			</Col>
			<Col >
				<Select
					placeholder="Select Project"
					style={{ width: 300 }}
					allowClear
					value={filterProject}
					options={projects}
					showSearch
					optionFilterProp={["label", "value"]}
					filterOption={
						(input, option) =>{
							const label = option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
							const value = option.value.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
							return label || value
						}
					}
					onChange={(ind) => { 
						console.log(ind)
						setQueryRequest((prev)=>(
							{...prev, filterProject: ind}
						)) 
					}}
				/>
				<div className='smallcheckpox'>
					<Checkbox size ="small" onChange={onCheckChanged}/> &nbsp; include closed projects
				</div>
			</Col>  
			<Col >
				<RangePicker 
					format={'ddd DD MMM yyyy'}
					value={[filterSatrtDate, filterEndDate]}
					size="small"
					onChange={(dates)=>{
						setQueryRequest((prev)=>(
							{	...prev, 
								filterSatrtDate: dates?.[0] ?? null, 
								filterEndDate: dates?.[1] ?? null
							}
						))
					}}
				/>
			</Col>  
			<Col span={24}>
				<Table
					size={'small'}
					bordered
					className='fs-small'
					pagination={{pageSize: localStore().pageSize, hideOnSinglePage: true, responsive: true, size: 'small'}}
					rowKey={data=> data.id}
					rowSelection={rowSelection}
					columns={columns}
					dataSource={expenseData}
					style={{marginTop: '5px'}}
					// onChange={onChange} 
				/>
			</Col>
			<Col span={24} >
				<Row justify="end" gutter={[20,200]}>
					<Col >
						<Button 
							type="primary" 
							danger
							disabled={ (selectedRows?.cantReject || !permission?.['APPROVAL'] || selectedRows['keys']?.length<1)}
							onClick={()=> multiAction('reject')}
						> 
							Reject
						</Button>
					</Col>
					<Col>
						<Button
							className={'success'}
							disabled={ (selectedRows?.cantApprove || !permission?.['APPROVAL'] || selectedRows['keys']?.length<1)}
							onClick={()=> multiAction('approve')}
						>
							Approve
						</Button>
					</Col>
					<Col>
						<Button
							className={'not-success'}
							disabled={ (selectedRows?.cantUnapprove || !permission?.['UNAPPROVAL'] || selectedRows['keys']?.length<1	)}
							onClick={()=> multiAction('unapprove')}
						>
							Unapprove
						</Button>
					</Col>
				</Row>
			</Col>
		</Row>
			{openModal&&<ExpenseSheetModal
				visible={openModal}
				close={closeModal}
				adminView= {true}
				callBack={callBack}
			/>}   
		</>
	)
}

export default ExpenseApproval