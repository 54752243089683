import React, {
  Component,
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import { Table, Row, Col, Form, Popconfirm, InputNumber, Typography } from 'antd';
import { CloseSquareFilled, CheckSquareFilled } from '@ant-design/icons'; //Icons
import { formatDate, formatFloat, localStore } from '../../service/constant';
import {
  getLeaveBalance,
  updateLeavebalance,
} from '../../service/leaveRequest-Apis';
import moment from 'moment';
const EditableContext = React.createContext(null);
const {Text} = Typography

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Row >
        {/* <Col span={10}> */}
        <Col >
          <Form.Item
            style={{
              margin: 0,
            }}
            name={dataIndex}
            rules={[
              {
                required: true,
                message: `${title} is required.`,
              },
            ]}
          >
            <InputNumber
              ref={inputRef}
              size="small"
              // onBlur={()=> setTimeout(() => { setEditing(!editing) }, 300)}
            />
          </Form.Item>
        </Col>
        <Col style={{ margin: 'auto 0' }} >
          <CloseSquareFilled
            style={{ color: 'red', fontSize: 24 }}
            onClick={() => setEditing(!editing)}
          />
        </Col>
        <Col style={{ margin: 'auto 0' }}>
          <Popconfirm
            title="You Want to Update Accrued Balance?"
            onConfirm={save}
            okText="Yes"
            cancelText="No"
          >
            <CheckSquareFilled style={{ color: 'green', fontSize: 24 }} />
          </Popconfirm>
        </Col>
      </Row>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

class LeaveBalance extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: 'Type',
        dataIndex: 'name',
        key: 'name',
        width: '30%',
      },
      {
        title: 'Accrued',
        dataIndex: 'carryForward',
        key: 'carryForward',
        className: 'editable-cell',
        width: '14%',
        editable: props.editable,
        render: (text) => formatFloat(text),
      },
      {
        title: 'Earned YTD',
        dataIndex: 'earned',
        key: 'earned',
        width: '14%',
        render: (text, record) =>
          formatFloat(record.balanceHours - record.carryForward + record.used),
      },
      {
        title: 'Used YTD',
        dataIndex: 'used',
        key: 'used',
        width: '14%',
        render: (text) => formatFloat(text),
      },
      {
        title: 'Balance',
        dataIndex: 'balanceHours',
        key: 'balanceHours',
        width: '14%',
        render: (text) => formatFloat(text),
      },
    ];

    this.state = {
      data: [],
      permissions: {},
      lastUpdate: null
    };
  }
  
  componentDidMount = () => {
    const { empId } = this.props;
    getLeaveBalance(empId).then((res) => {
      if (res?.success) {
        this.setState({
          data: res.data,
          lastUpdate: moment().isBefore(moment().set({ date: 15 }))
            ? moment().set({ date: 15, month: moment().month() - 1 })
            : moment().set({ date: 15 }),
        });
      }
    });
  };

  handleSave = (row) => {
    updateLeavebalance(row.id, row).then((res) => {
      if (res.success) {
        console.log(res.data);
        const data = [...this.state.data];
        const index = data.findIndex((item) => row.id === item.id);
        const item = data[index];
        data.splice(index, 1, { ...item, ...res.data });

        this.setState({
          data,
        });
      }
    });
  };

  render() {
    const { data, lastUpdate } = this.state;
    const { style } = this.props;
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          ...col,
          record,
          handleSave: this.handleSave,
        }),
      };
    });
    return (
      <Row>
        <Col span={24}>
          <Table
            components={components}
            style={style}
            bordered
            rowKey={(data) => data.id}
            columns={columns}
            dataSource={data}
            size="small"
            className="fs-small"
            tableLayout="fixed"
          />
        </Col>
        <Col>
          <Text type="danger">Last Updated: {formatDate(lastUpdate, true,true)} </Text>
        </Col>
      </Row>
    );
  }
}

export default LeaveBalance;
