export const EmployeeFilterColumns= {
    id: { type: 'Input', value: '', label: 'Code', showInColumn: true },
    firstName: {
      type: 'Input',
      value: '',
      label: 'First Name',
      showInColumn: true,
    },
    lastName: {
      type: 'Input',
      value: '',
      label: 'Last Name',
      showInColumn: true,
    },
    phoneNumber: {
      type: 'Input',
      value: '',
      label: 'Phone Number',
      showInColumn: true,
    },
    email: {
      type: 'Input',
      value: '',
      label: 'Personal Email',
      showInColumn: true,
    },
    Action: {
      type: 'Input',
      value: '',
      label: '',
      showInColumn: true,
      disabled: true,
    },
    stateId: {
      type: 'none',
      multi: true,
      value: [],
      label: 'State',
      showInColumn: false,
      disabled: false,
    },
    clearanceLevel: {
      type: 'none',
      multi: true,
      value: [],
      label: 'clearanceLevel',
      showInColumn: false,
      disabled: false,
    },
    gender: {
      type: 'Select',
      multi: true,
      value: [],
      label: 'Gender',
      showInColumn: false,
    },
    address: {
      type: 'none',
      value: '',
      label: 'Residential Address',
      showInColumn: false,
      disabled: false,
    },
    role: {
      type: 'none',
      multi: true,
      value: [],
      label: 'Role',
      showInColumn: false,
      disabled: false,
    },
    employeeStatus: {
      type: 'none',
      multi: true,
      value: [],
      label: 'Employee Status',
      showInColumn: false,
      disabled: false,
    },
    leaveRequestPolicyId: {
      type: 'none',
      multi: true,
      value: [],
      label: 'Leave Policy',
      showInColumn: false,
      disabled: false,
    },
    isActive: {
      type: 'none',
      multi: true,
      value: [],
      label: 'Status',
      showInColumn: false,
      disabled: false,
    },
    contractStartDate: {
      type: 'Date',
      value: null,
      label: 'Contract Start Date',
      showInColumn: true,
    },
    contractEndDate: {
      type: 'Date',
      value: null,
      label: 'Contract End Date',
      showInColumn: true,
      disabled: true,
    },
    minSalary: {
      type: 'Input',
      value: '',
      label: 'Min Salary',
      showInColumn: true,
    },
    maxSalary: {
      type: 'Input',
      value: '',
      label: 'Max Salary',
      showInColumn: true,
    },
    payFrequency: {
      type: 'none',
      multi: true,
      value: [],
      label: 'Pay Frequency',
      showInColumn: false,
      disabled: false,
    },
    lineManagerId: {
      type: 'none',
      multi: true,
      value: [],
      label: 'Line Manager',
      showInColumn: false,
      disabled: false,
    },
}