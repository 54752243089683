import { message as messageAlert } from 'antd';
import axios from 'axios';

import { Api, headers, jwtExpired, setToken } from './constant';

const url = `${Api}/expense-types`;

export const getList = () => {
  return axios
    .get(url, { headers: headers() })
    .then((res) => {
      const { success, data, message } = res.data;
      jwtExpired(message);
      if (success) setToken(res?.headers?.authorization);

      return { success: success, data: data };
    })
    .catch((err) => {
      return {
        error: 'Please login again!',
        success: false,
        message: err.message,
      };
    });
};

export const getListAlt = () => {
  return axios
    .get(`${url}?select=true`, { headers: headers() })
    .then((res) => {
      const { success, data, message } = res.data;
      jwtExpired(message);
      if (success) setToken(res?.headers?.authorization);

      return { success: success, data: data };
    })
    .catch((err) => {
      return {
        error: 'Please login again!',
        success: false,
        message: err.message,
      };
    });
};

export const addList = (data) => {
  messageAlert.loading({ content: 'Loading...', key: 1 }, 5);
  return axios
    .post(url, data, { headers: headers() })
    .then((res) => {
      const { success, message } = res.data;
      jwtExpired(message);
      messageAlert.success({ content: message, key: 1 }, 5);
      if (success) setToken(res?.headers?.authorization);
      return { success };
    })
    .catch((err) => {
      messageAlert.error({ content: 'Error!', key: 1 }, 5);
      return {
        error: 'Please login again!',
        status: false,
        message: err.message,
      };
    });
};

export const delLabel = (id) => {
  return axios
    .delete(url + `/${id}`, { headers: headers() })
    .then((res) => {
      const { success, message } = res.data;
      jwtExpired(message);
      setToken(res?.headers?.authorization);
      if (success) return success;
    })
    .catch((err) => {
      return {
        error: 'Please login again!',
        status: false,
        message: err.message,
      };
    });
};

export const editLabel = (data) => {
  messageAlert.loading({ content: 'Loading...', key: data.id });
  return axios
    .put(url + `/${data.id}`, data, { headers: headers() })
    .then((res) => {
      const { success, message } = res.data;
      jwtExpired(message);
      messageAlert.success({ content: message, key: data.id }, 5);
      setToken(res?.headers?.authorization);
      if (success) return success;
    })
    .catch((err) => {
      messageAlert.error({ content: 'Error!', key: data.id });
      return {
        error: 'Please login again!',
        status: false,
        message: err.message,
      };
    });
};
